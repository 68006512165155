import { HttpClient } from "@angular/common/http";
import { NavigationEnd, NavigationStart, Route, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { Observable, Subscriber } from "rxjs";
import { CustomerService } from "../services/customer.service";
import { AuthService } from "@auth0/auth0-angular";


export function initializeBulwarqApp(customerService: CustomerService, router: Router, authService: AuthService): () => Promise<any> {
    return () => new Promise((resolve, reject) => {
        customerService.incrementVersion();
        
        let original_url: string = "";

        router.events.pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationEnd) => {

                if (original_url == "") {
                    original_url = event.url;
                }

                authService.isAuthenticated$.subscribe((yep_im_authenticated) => {

                    if (yep_im_authenticated) {

                        console.log(original_url);

                        
                        customerService.getSubscriber().subscribe((s) => {
                            console.log(s);                                

                            if (!s.is_customer) {
                                //router.navigate(["welcome", "onboard"]);
                                authService.logout({
                                    returnTo: "https://www.bulwarq.com/plans-pricing"
                                });
                                
                            } else {
                                customerService.getMe().subscribe((me) => {
                                    if (!me.customer.completed_setup) {
                                        console.log(event.url);
        
                                        if (event.url != "/welcome") {
                                            router.navigate(['welcome']);
                                        }
        
                                    }
                                });
                            }
                        });
                                                
                    }

                });
            });



        resolve("ok");


    });
}