import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CustomerResponse } from '../classes/customersresponse';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { Device } from '../classes/device';
import { UpdateCustomerRequest } from '../classes/updatecustomerrequest';
import { SaveNewDeviceRequest } from '../classes/savenewdevicerequest';
import { AppleProfileResponse } from '../classes/appleprofileresponse';
import { CustomerRequestResponse } from '../classes/customerrequestresponse';
import { DomainResearchResponse } from '../classes/domainresearch';
import { Incident } from '../classes/incident';
import { InvitationAddRequest, InvitationAddResponse, InvitationsResponse } from '../classes/invitations';
import { BulwarqUser, BulwarqUserResponse } from '../classes/users';
import { Subscriber } from '../classes/subscriber';
import { PlanPolicy, PlanPolicyResponse } from '../classes/planpolicy';

// Comment
@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  version:Number = 0;

  getMe() : Observable<CustomerResponse> {    
    return this.http.get<CustomerResponse>(`https://app.bulwarq.com/prod/customers/me?${this.version}`)
    .pipe(
      shareReplay(1)
   );
  }

  getDomainResearch(domain:String) : Observable<DomainResearchResponse> {    
    return this.http.get<DomainResearchResponse>(`https://app.bulwarq.com/prod/domain/research?domain_name=${domain}`)
    .pipe(
      shareReplay(1)
   );
  }

  getCustomerRequests() : Observable<CustomerRequestResponse> {    
    return this.http.get<CustomerRequestResponse>(`https://app.bulwarq.com/prod/customers/me/requests?${this.version}`)
    .pipe(
      shareReplay(1)
   );
  }

  getInvitations() : Observable<InvitationsResponse> {    
    return this.http.get<InvitationsResponse>(`https://app.bulwarq.com/prod/customers/me/invitations?${this.version}`)
    .pipe(
      shareReplay(1)
   );
  }

  getUsers() : Observable<BulwarqUserResponse> {    
    return this.http.get<BulwarqUserResponse>(`https://app.bulwarq.com/prod/customers/me/users?${this.version}`)
    .pipe(
      shareReplay(1)
   );
  }

  getPLans() : Observable<PlanPolicyResponse> {    
    return this.http.get<PlanPolicyResponse>(`https://app.bulwarq.com/prod/plans?2`)
    .pipe(
      shareReplay(1)
   );
  }  

  getSubscriber() : Observable<Subscriber> {    
    return this.http.get<Subscriber>(`https://app.bulwarq.com/prod/customers/subscriber?${this.version}`)
    .pipe(
      shareReplay(1)
   );
  }

  sendVerificationEmail() : Observable<any> {    
    return this.http.post(`https://app.bulwarq.com/prod/customers/subscriber/email?${this.version}`, {})
    .pipe(
      shareReplay(1)
   );
  }



  addInvitation(invite: InvitationAddRequest) : Observable<InvitationAddResponse> {
    this.incrementVersion();
    return this.http.post<InvitationAddResponse>(`https://app.bulwarq.com/prod/customers/me/invitations`, {
      email: invite.email,
      role: invite.role,
      action: "add"
    });
  }   

  deleteInvitation(email: string) : Observable<any> {
    this.incrementVersion();
    return this.http.post(`https://app.bulwarq.com/prod/customers/me/invitations`, {
      email: email,      
      action: "remove"
    });
  }   

  deleteUser(email: string) : Observable<any> {
    this.incrementVersion();
    return this.http.post(`https://app.bulwarq.com/prod/customers/me/users`, {
      email: email,      
      action: "remove"
    });
  }     



  updateCustomer(updateCustomerReq: UpdateCustomerRequest): Observable<any> {
    this.incrementVersion();
    return this.http.post(`https://app.bulwarq.com/prod/customers/me`, updateCustomerReq);
  }

  addIp(address: String) : Observable<any> {
    this.incrementVersion();
    return this.http.post(`https://app.bulwarq.com/prod/customers/me/addresses`, {
      address: address,
      action: "add"             
    });
  }  

  removeIp(address: String) : Observable<any> {
    this.incrementVersion();
    return this.http.post(`https://app.bulwarq.com/prod/customers/me/addresses`, {
      address: address,
      action: "remove"             
    });
  }  

  addDeviceCategory(category: String, device: String) {
    this.incrementVersion();
      return this.http.post(`https://app.bulwarq.com/prod/customers/me/categories/${category}?device=${device}`, null)
      .subscribe( (x) => {
          console.log(x);
      });
  }

  removeDeviceCategory(category: String, device: String) {
    this.incrementVersion();
    return this.http.delete(`https://app.bulwarq.com/prod/customers/me/categories/${category}?device=${device}`, {})
    .subscribe( (x) => {
      console.log(x);
  });
  }


  addCategory(category: String) {
    this.incrementVersion();
      return this.http.post(`https://app.bulwarq.com/prod/customers/me/categories/${category}`, null)
      .subscribe( (x) => {
          console.log(x);
      });
  }

  removeCategory(category: String) {
    this.incrementVersion();
    return this.http.delete(`https://app.bulwarq.com/prod/customers/me/categories/${category}`, {})
    .subscribe( (x) => {
      console.log(x);
  });
  }

  addNotificationCategory(category: String) {
    this.incrementVersion();
      return this.http.post(`https://app.bulwarq.com/prod/customers/me/notification_categories/${category}`, null)
      .subscribe( (x) => {
          console.log(x);
      });
  }

  removeNotificationCategory(category: String) {
    this.incrementVersion();
    return this.http.delete(`https://app.bulwarq.com/prod/customers/me/notification_categories/${category}`, {})
    .subscribe( (x) => {
      console.log(x);
  });
  }  

  deleteDevice(device: Device) : Observable<any> {
    this.incrementVersion();
    if (device != null) {
    return this.http.post(`https://app.bulwarq.com/prod/customers/me/devices`, {
        mac: device.ip_address,
        name: device.name,
        action: "remove"
    });    
  }
  }




  saveDevice(device: Device, new_ip:String = "") : Observable<any> {
    this.incrementVersion();
    if (device != null) {
    return this.http.post(`https://app.bulwarq.com/prod/customers/me/devices`, {      
        mac: device.ip_address,
        new_ip : new_ip,
        name: device.name,
        action: "edit",
        block_mode : device.block_mode,
        form: device.form,
        platform: device.platform
    });
  }
  }

  saveNewDevice(newDeviceRequest: SaveNewDeviceRequest) : Observable<any> {
    this.incrementVersion();
    return this.http.post(`https://app.bulwarq.com/prod/customers/me/devices`, {        
        name: newDeviceRequest.name,
        new_ip : newDeviceRequest.new_ip,
        platform: newDeviceRequest.devicePlatform,
        form: newDeviceRequest.deviceForm,
        action: "new"
    });
  
  }

  getDeviceProfile(deviceId: String) : Observable<AppleProfileResponse> {
    this.incrementVersion();
    return this.http.get<AppleProfileResponse>(`https://app.bulwarq.com/prod/customers/me/devices/${deviceId}/ios_profile`, {        
    });  
  }

  getIncidents() : Observable<Incident[]> {    
    return this.http.get<Incident[]>(`https://app.bulwarq.com/prod/customers/me/incidents?${this.version}`, {        
    });  
  }



  incrementVersion()  {
      this.version = this.version.valueOf() + 1;      
      localStorage.setItem("customer-cache-key", this.version.toString());
  }


  constructor(
    private http: HttpClient
    ) { 

      let val = localStorage.getItem("customer-cache-key");
      if (!val) {
        this.version = 0;
      }
      else {
        this.version = parseInt(val);
      }
    }
}
