import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {

  constructor(private customerService:CustomerService, 
    private router:Router) { }

  ngOnInit(): void {
    this.customerService.getMe().subscribe((i) => {
        if (i.customer.completed_setup) {                    
          this.router.navigate(['dashboard']);
        } 
    });
  }

}
