import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'uil-home-alt',
        link: '/dashboard',
    },
    {
        id: 2200,
        label: 'Incidents',
        icon: 'uil-fire',
        link: '/incidents',
    },    
    {
        id: 2400,
        label: 'Devices',
        icon: 'uil-wifi-router',
        link: '/devices',
    },    
    {
        id: 2400,
        label: 'Allow List',
        icon: 'uil-smile',
        link: '/allowlist',
    },    
    {
        id: 2410,
        label: 'Block List',
        icon: 'uil-frown',
        link: '/blocklist',
    },    
    {
        id: 2400,
        label: 'Alerts',
        icon: 'uil-exclamation-triangle',
        link: '/alerts',
    },    
    {
        id: 2500,
        label: 'Users',
        icon: 'uil-users-alt',
        link: '/users',
    },    


];

